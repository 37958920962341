<template>
  <div>
     <el-card>
       <el-table :data="drayCostDetails" style="font-size: smaller;width: 100%">
            <el-table-column fixed prop="fromArea" :label="$t('msg.dray.fromArea')" align="center" width="120"></el-table-column>
            <el-table-column fixed prop="ctnrid" label="DesType" align="center" width="120"></el-table-column>
            <el-table-column fixed prop="toArea" :label="$t('msg.dray.toArea')" align="center" width="120"></el-table-column>
            <el-table-column fixed prop="ctnrType" :label="$t('msg.dray.ctnrType')" align="center" width="120"></el-table-column>
            <el-table-column fixed prop="triAxle" label="CtnrCounts" align="center" width="140"></el-table-column>
            <el-table-column prop="normTotal" :label="$t('msg.dray.normTotal')" align="center" width="130">
              <template #default="scope">
                <span >$ {{scope.row.normTotal}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="note" label="Notes" align="center" width="250"></el-table-column>
            <el-table-column prop="drayage" :label="$t('msg.dray.drayage')" align="center" width="100">
              <template #default="scope">
                <span >$ {{scope.row.drayage}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="pierPass" :label="$t('msg.dray.pierPass')" align="center" width="100">
              <template #default="scope">
                <span >$ {{scope.row.pierPass}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="chassisRent" :label="$t('msg.dray.chassisRent')" align="center" width="110">
              <template #default="scope">
                <span >$ {{scope.row.chassisRent}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="terminalFee" :label="$t('msg.dray.terminalFee')" align="center" width="110">
              <template #default="scope">
                <span >$ {{scope.row.terminalFee}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="portCongest" label="PortCongest" align="center" width="120">
              <template #default="scope">
                <span >$ {{scope.row.portCongest}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="fuelSurcharge" label="FSC" align="center" width="100">
              <template #default="scope">
                <span >$ {{scope.row.fuelSurcharge}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="cleanTruck" :label="$t('msg.dray.cleanTruck')" align="center" width="120">
              <template #default="scope">
                <span >$ {{scope.row.cleanTruck}}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="Create Order" align="center" width="200">
              <template #default="scope">
                <el-tooltip class="box-item" effect="dark" content="Data Source: Manuel Input" placement="top-start">
                  <el-button link type="text" size="small" @click="createOrder(scope.row)" :disabled="scope.row.note.includes('ordered')">Manuel</el-button>
                </el-tooltip>
                <el-tooltip class="box-item" effect="dark" content="Data Source: Tracking Systems" placement="top-start">
                  <el-button link type="text" size="small" @click="createOrder(scope.row)" disabled>Tracking</el-button>
                </el-tooltip>
              </template>
            </el-table-column>
       </el-table>
     </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'

import axios from 'axios'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'drayQuotes',
  props: {
    orderTag: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const drayCostDetails = ref([])
    const router = useRouter()
    onMounted(() => {
      const param = props.orderTag.split(';')[0]
      const reqURL = process.env.VUE_APP_BASE + '/dray/getQuotesInBatchModel'
      axios.post(reqURL, param).then((response) => {
        drayCostDetails.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    })
    const createOrder = (row) => {
      sessionStorage.setItem('drayCost', JSON.stringify(row))
      const orderTag = props.orderTag
      router.push(`/dray/manuelOrder/${orderTag}`)
    }
    return { drayCostDetails, createOrder }
  }
})
</script>
